import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Lottie from "lottie-react";
import Image from "next/image";
import { useSession } from "next-auth/react";

import GiftAnimation from "@musicfy/assets/animations/gift.json";
import {
  EFeatures,
  FEATURE_ROUTES,
} from "@musicfy/components/Navigation/constants";
import { useSubscriptionContext } from "@musicfy/libs/SubscriptionProvider";

import Button from "../Button";

const getLoginStreakEmoji = (loginStreak: number) => {
  switch (loginStreak) {
    case 0:
      return "😢";
    case 1:
    case 2:
    case 3:
      return "🙂";
    case 4:
    case 5:
    default:
      return "🚀";
  }
};

const Welcome = (): JSX.Element => {
  const { data } = useSession();
  const { consecutiveLoginDays } = useSubscriptionContext();
  const user = data?.user;

  if (!user) {
    return (
      <div className="rounded-[18px] flex-grow min-h-52 flex flex-col justify-between lg:px-12 lg:py-8 p-6 bg-gradient-to-tr from-pink-200 via-pink-300 to-purple-light/50 bg-gray-1000">
        <div>
          <div className="font-semibold text-18">Hey there,</div>
          <div className="font-bold text-32 mt-2">
            Create an account to get started!
          </div>
        </div>

        <Button
          path={FEATURE_ROUTES[EFeatures.SIGN_UP]}
          variant="outlined"
          style="secondary"
          className="!text-white !font-medium !bg-white/5 xl:hover:!bg-white/15"
          postIcon={<ArrowForwardIcon />}
        >
          Create an account
        </Button>
      </div>
    );
  }

  const hasEnoughConsecutiveLoginDays = consecutiveLoginDays.length >= 5;
  const daysUntilNextReward = hasEnoughConsecutiveLoginDays
    ? 0
    : 5 - consecutiveLoginDays.length;

  return (
    <div className="rounded-[18px] lg:px-12 lg:py-8 relative p-6 bg-gradient-to-tr from-pink-200 via-pink-300 to-purple-light/50 bg-gray-1000">
      <div className="flex gap-4 justify-between">
        <div className="flex flex-shrink-0 gap-4">
          <div className="lg:block hidden">
            {!!user.image ? (
              <Image
                unoptimized
                src={user?.image ?? ""}
                alt="User Avatar"
                width={56}
                height={56}
                referrerPolicy="no-referrer"
                className="rounded-full transition-all relative before:absolute before:left-0 before:top-0 before:h-8 before:w-8 before:rounded-4 before:bg-purple before:content-['']"
              />
            ) : (
              <div className="rounded-full font-bold flex items-center justify-center w-14 h-14 bg-purple">
                {user.name?.charAt(0) ?? ""}
              </div>
            )}
          </div>
          <div>
            <div className="font-semibold lg:text-26 text-22">
              Hey, {user.name}!
            </div>
            <div className="text-14 mt-1 font-semibold">
              Current Login Streak - {consecutiveLoginDays.length} Days{" "}
              {getLoginStreakEmoji(consecutiveLoginDays.length)}
            </div>
          </div>
        </div>
        {hasEnoughConsecutiveLoginDays && (
          <Lottie
            animationData={GiftAnimation}
            className="h-24 absolute lg:top-0 lg:right-0 -top-4 -right-8"
          />
        )}
      </div>
      <div className="relative mt-6 justify-center overflow-hidden border border-white/60 rounded-12 text-14 text-white">
        <div className="w-full h-full bg-white/5 backdrop-blur-3xl absolute left-0 top-0 z-20" />
        <div className="absolute h-full aspect-square rounded-full bg-purple-light/50 z-10" />
        <div className="absolute h-full -top-10 left-1/3 aspect-square rounded-full bg-pink-200/50 z-10" />
        <div className="absolute h-full right-0 -bottom-10 aspect-square rounded-full bg-purple/50 z-10" />
        <div className="z-30 relative overflow-hidden gap-3 bg-gray-1000/40 text-white p-3">
          <div className="font-bold uppercase tracking-wide">Next Reward</div>
          <div className="flex items-center justify-between gap-2 mt-5">
            {Array.from({ length: consecutiveLoginDays.length }).map(
              (_, index) => {
                return (
                  <div
                    className="w-full h-2 rounded-full bg-pink-200"
                    key={index}
                  />
                );
              }
            )}
            {Array.from({ length: daysUntilNextReward }).map((_, index) => (
              <div
                className="w-full h-2 rounded-full bg-white/50"
                key={index}
              />
            ))}
          </div>
          <div className="mt-6">
            Keep up your login streak to earn a{" "}
            <span className="font-bold">free voice training slot</span> every 5
            days!
          </div>
        </div>
      </div>
      {hasEnoughConsecutiveLoginDays && (
        <Button
          path={FEATURE_ROUTES[EFeatures.VOICES]}
          variant="outlined"
          style="secondary"
          className="!text-white !font-medium !bg-white/5 xl:hover:!bg-white/15 mt-6"
          postIcon={<ArrowForwardIcon className="!w-4 !h-6" />}
        >
          Create Voice
        </Button>
      )}
    </div>
  );
};

export default Welcome;
